import React from "react"
import { graphql, PageProps } from "gatsby"
import { NotFoundPageQuery } from "../../types/gatsby-graphql.generated"
import { jsonPage } from "../json/content/page"
import { SinglePageHeadLayout, SinglePageLayout } from "../layouts/single"
import { ContentHeader } from "../views/content"
import { Markdown } from "../views/markdown"

export function Head({ data }: PageProps<NotFoundPageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { title, description, path } = markdownRemark

    return (
        <SinglePageHeadLayout
            isHome={false}
            title={title}
            description={description}
            path={path}
            json={jsonPage(markdownRemark)}
        />
    )
}

export default function NotFoundPage({ data }: PageProps<NotFoundPageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { title, html } = markdownRemark

    return (
        <SinglePageLayout isHome={false}>
            <ContentHeader>
                <h1>{title}</h1>
            </ContentHeader>
            <Markdown html={html} />
        </SinglePageLayout>
    )
}

export const pageQuery = graphql`
    query NotFoundPage {
        markdownRemark(path: { eq: "/404" }, published: { eq: true }) {
            path
            title
            publishedAt
            updatedAt
            description
            html
        }
    }
`
